<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card title="Edit Profile" class="mb-base" style="min-height: 100%">
                <div class="vx-row mr-4">
                    <div class="vx-col w-full" style="display: flex">
                        <div class="w-1/1 w-full ml-3">
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-1/5 w-full">
                                    <span>Mobile</span>
                                </div>
                                <div class="vx-col sm:w-4/5 w-full">
                                    <vs-input name="mobile" v-validate="'numeric'" class="w-full" v-model="data.mobile" />
                                    <span
                                        class="text-danger text-sm"
                                        v-show="errors.has('mobile')"
                                        >{{ errors.first('mobile') }}
                                    </span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-1/5 w-full">
                                    <span>Address</span>
                                </div>
                                <div class="vx-col sm:w-4/5 w-full">
                                    <vs-textarea name="address" class="w-full" v-model="data.address" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vx-col w-full mt-5" style="display: flex">
                        <div class="w-1/1 w-full ml-3">
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-1/5 w-full">
                                    <span></span>
                                </div>
                                <div class="vx-col sm:w-4/5 w-full">
                                    <vs-button @click="handleSubmit()" class="mr-4">Submit</vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        this.getData()
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                data: {},
            };
        },
        getData(){
            this.$vs.loading();
            this.$http
            .get("api/v1/setting/user-personal").then(resp => {
                this.$vs.loading.close();
                if (resp.status == "success") {
                    this.data = resp.data
                }
            });
        },
        paramData() {
            return {
                mobile: this.data.mobile,
                address: this.data.address,
                personal_id: this.data.id,
            };
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {

                if (result) {
                    this.$vs.loading();
                    this.putData();
                }
                
                console.log(this.paramData())
            });
        },
        putData() {
        this.$http
            .put("api/v1/setting/personal", this.paramData())
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Profile Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
    watch: {
        "data.mobile": function(val) {
            val = val.toString().replace(/\./g, "");
            if (isNaN(parseInt(val))) {
                this.errors.add({
                    field: "mobile",
                    msg: "The Mobile field is number required"
                });
            } else {
                this.errors.clear()
            }
        }
    }
}
</script>